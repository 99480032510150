import React, {useState, useEffect, useRef} from 'react'
import './addProduct.css'
import Sidebar from '../../sidebar/sidebar';
import { IoReturnUpBack } from "react-icons/io5";
import { FaSquarePlus } from "react-icons/fa6";
import { FaAngleDown, FaPlus  } from "react-icons/fa6";
import autoAnimate from '@formkit/auto-animate';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { APIurl } from '../../../ApiService';

import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiCheckSquare } from "react-icons/fi";
import { InfinitySpin } from 'react-loader-spinner';


//TODO:  create the image upload drag and drop

function AddProduct() {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const redirectToProducts = () => {
        navigate('/administrator/produse');
    }

    const [categories, setCategories] = useState([]);

    const fetchCategories = async() => {
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const tokenData = {
                token: savedToken
            };
            try {
                const response = await axios.post(`${APIurl}/api/admingetproductcategories`, tokenData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else{
                        console.log(response.data.product_categories);
                        console.log('Product categories fetched');
                        const products_categories = response.data.product_categories;
                        console.log(products_categories);
                        setCategories(products_categories);
                        return;
                    }
                }
            } catch (error) {
                console.error("Error fetching products categories:", error);
            }

        }else{
            navigate('/login');
        }
    }

    useEffect(() => {
        fetchCategories();
    }, []);

    const [inputs, setInputs] = useState({
        product_code: '',
        name: '',
        description: '',
        price: '',
        discount : false,
        discounted_price: '',
        category: '',

    });

    const [inputErrors, setInputErrors] = useState({
        product_code: false,
        name: false,
        description: false,
        price: false,
        discount: false,
        discounted_price: false,
        category: false,
    });

    const handleInputsChange = (e, fieldName) => {
        const value = e.target.value;
    
        // Update the input value without trimming
        setInputs({
            ...inputs,
            [fieldName]: value,
        });
    
        // Validate the input by trimming whitespace from the start and end
        setInputErrors({
            ...inputErrors,
            [fieldName]: value.trim() === '',
        });
    };

    // categories dropdown
    const CategDropDownParent = useRef(null)
    useEffect(() => {
        CategDropDownParent.current && autoAnimate(CategDropDownParent.current)
    }, [CategDropDownParent])
    const [categoriesDropwonOpen, setCategoriesDropwonOpen] = useState(false);
    const toggleCategoriesDropdown = () =>{
        setCategoriesDropwonOpen(!categoriesDropwonOpen);
    }
    const closeCategoriesDropdown = () =>{
        setCategoriesDropwonOpen(false);
    }

    const handleCategoryChange = (categoryID) =>{
        setInputs({
            ...inputs,
            category: categories[categoryID].name,
        });
        closeCategoriesDropdown();
    }


    const basePricesListParent = useRef(null)
    useEffect(() => {
        basePricesListParent.current && autoAnimate(basePricesListParent.current)
    }, [basePricesListParent])
    const fixedPercentagePricesListParent = useRef(null)
    useEffect(() => {
        fixedPercentagePricesListParent.current && autoAnimate(fixedPercentagePricesListParent.current)
    }, [fixedPercentagePricesListParent])
    const rangePercentagePricesListParent = useRef(null)
    useEffect(() => {
        rangePercentagePricesListParent.current && autoAnimate(rangePercentagePricesListParent.current)
    }, [rangePercentagePricesListParent])
    const additionalPricesListParent = useRef(null)
    useEffect(() => {
        additionalPricesListParent.current && autoAnimate(additionalPricesListParent.current)
    }, [additionalPricesListParent])
    // prices list
    const [prices_list, setPricesList] = useState([]);

    const addPrice = (price_type) =>{
        if(price_type === 'rangePercentage'){
            setPricesList([...prices_list, {
                name: '',
                options: [{
                    name: 'range',
                    value: '',
                    min: '',
                    max: '',
                }],
                price_type: price_type,
            }]);
        }else{
            setPricesList([...prices_list, {
                name: '',
                options: [],
                price_type: price_type,
            }]);
        }
    }
    const deletePrice = (index) =>{
        setPricesList(prices_list.filter((_, i) => i !== index));
    }
    const updatePrice = (index, prop, value) =>{
        const new_prices_list = [...prices_list];
        const price = prices_list[index];
        price[prop] = value;
        new_prices_list[index] = price;
        setPricesList([...new_prices_list]);
    }

    useEffect(() => {
        console.log(prices_list);
    }, [prices_list]);


    const addPriceOption = (price_index) =>{
        const new_prices_list = [...prices_list];
        const price = new_prices_list[price_index];
        price.options.push({
            name: '',
            value: '',
            min: '',
            max: '',
        });
        new_prices_list[price_index] = price;
        setPricesList([...new_prices_list]);
    }

    const deletePriceOption = (price_index, option_index) =>{
        const new_prices_list = [...prices_list];
        const price = prices_list[price_index];
        price.options.splice(option_index, 1);
        new_prices_list[price_index] = price;
        setPricesList([...new_prices_list]);
    }

    const updatePriceOption = (price_index, option_index, prop, value) =>{
        const new_prices_list = [...prices_list];
        const price = new_prices_list[price_index];
        price.options[option_index][prop] = value;
        new_prices_list[price_index] = price;
        setPricesList([...new_prices_list]);
    }

    //  image upload
    const [imagePreview, setImagePreview] = useState(process.env.PUBLIC_URL + '/res/images/products/product1.png');
    const [isDragging, setIsDragging] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setImagePreview(imageUrl);
        }
    };

    //  handle product submit
    function filterArray(arr) {
        return arr
            .filter(item => item.name.trim() !== "" && item.options.length > 0)
            .map(item => {
                return {
                    ...item,
                    options: item.options.filter(option => option.name.trim() !== "" && (option.value.trim() !== "" || (option.min.trim() !== "" && option.max.trim() !== "")))
                };
            });
    }

    const [product_submit_spinner, setProductSubmitSpinner] = useState(false);
    const [product_submit_status, setProductSubmitStatus] = useState('');

    const handleProductSubmit = async() =>{
        if(inputs.name.trim() === '' || inputs.description.trim() === '' || inputs.price.trim() === '' || inputs.category === ''){
            alert('Completeaza campurile cu: Denumire Produs, Descriere Produs, Pret, Categorie!');
            return;
        }

        // check prices and filter out prices without name and options
        const first_prices_filter = filterArray(prices_list);
        const filtered_prices_list = filterArray(first_prices_filter);
        console.log(filtered_prices_list);

        if(filtered_prices_list.length === 0){
            alert('Completeaza toate optiunile si preturile lor!');
            return;
        }
        

        const product = {
            name: inputs.name,
            description: inputs.description,
            price: inputs.price,
            discount: inputs.discount,
            discounted_price: inputs.discounted_price,
            category: inputs.category,
            prices_list: filtered_prices_list,
        };
        console.log(product);
        // TODO:  send product to backend
        setProductSubmitSpinner(true);
        setProductSubmitStatus('');
        await sendProduct(product);
    }

    const sendProduct = async(product) =>{
        const savedToken = cookies.get("authToken");
        if(savedToken){
            console.log("🔐 You are logged in with this token 🔐 \n" + savedToken);
            const reqData = {
                token: savedToken,
                product: product,
            };
            try {
                const response = await axios.post(`${APIurl}/api/admincreateproduct`, reqData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });    
                const responseStatus = response.data.status;
                const dataMessage = response.data.data;
                if (responseStatus === 'error') {
                    setProductSubmitStatus('error');
                    setProductSubmitSpinner(false);
                    return;
                } else {
                    if(dataMessage === 'Not admin'){
                        navigate('/login');
                    }else if(dataMessage === 'Product not created'){
                        setProductSubmitStatus('failed');
                        setProductSubmitSpinner(false);
                        alert('Failed to create a new product!');
                        return;
                    }else if(dataMessage === 'Product created'){
                        setProductSubmitStatus('success');
                        setProductSubmitSpinner(false);
                        setIsSuccessCounterActive(true);
                        return;
                    }
                }
            } catch (error) {
                setProductSubmitStatus('error');
                setProductSubmitSpinner(false);
                console.error("Error fetching products categories:", error);
            }
        }else{
            navigate('/login');
        }
    };

    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement.type === "number" &&
                document.activeElement.classList.contains("noscroll")) {
                document.activeElement.blur();
            }
        };

        // Add the wheel event listener when the component mounts
        document.addEventListener("wheel", handleWheel);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);

    const [successCount, setSuccessCount] = useState(3);
    const [isSuccessCounterActive, setIsSuccessCounterActive] = useState(false);

    useEffect(() => {
        let timer;
        if (isSuccessCounterActive && successCount > 0) {
            timer = setTimeout(() => setSuccessCount(successCount - 1), 1000);
        } else if (successCount === 0) {
            navigate('/administrator/produse');
        }
        return () => clearTimeout(timer); // Cleanup timer on component unmount or countdown reset
    }, [successCount, isSuccessCounterActive]);


    return (
        <div className='adminPage'>
            <Sidebar activeTab={'produse'}/>
            <div className='comenziSection'>
                <div className='produseSubcategoryHeader'>
                    <IoReturnUpBack className='produseHeaderBackArrow' onClick={redirectToProducts}/>
                    <p className='produseHeaderTitle'>Adaugă Produs</p>
                </div>

                <div className='produse-container'>
                    <p className='produse-container-directory'><span style={{opacity: 0.4, cursor: 'pointer'}} onClick={redirectToProducts}>Toate Produsele / </span>Adaugă Produs</p>
                
                <div className='add-product-section'>
                    <div className='add-product-container-left'>
                        <div className='add-product-container-left-top'>
                            <FaSquarePlus className='add-product-container-left-top-icon'/>
                            <p>Completează datele produsului</p>
                        </div>
                        <div className='add-product-container-left-content'>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Imagine Produs<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                    <div className='add-product-container-left-content-image-upload-box'
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                    >
                                        <input
                                            type='file'
                                            id='file-upload'
                                            className='file-input'
                                            accept='image/*'
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor='file-upload' className='add-product-image-upload-label'>
                                            <FaPlus className='add-product-container-left-content-image-upload-icon' />
                                            <p>Drag & Drop la imagine sau apasă pentru a selecta din fisiere</p>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Denumire Produs<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                    <input type="text" value={inputs.name} onChange={(e) => handleInputsChange(e, 'name')} />
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Descriere Produs<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                    <textarea value={inputs.description} onChange={(e) => handleInputsChange(e, 'description')} />
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Pret<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                    <input type="number" value={inputs.price} onChange={(e) => handleInputsChange(e, 'price')} className='add-product-prices-list-item-input-price noscroll'/>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Categorie<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                    <div className='add-product-categories-dropdown' ref={CategDropDownParent}>
                                        <div className='add-product-categories-dropdown-top'style={{cursor: 'pointer'}} onClick={() => toggleCategoriesDropdown()}>
                                            <div className='add-product-categories-dropdown-top-value'>
                                                <p>{inputs.category !== '' ? inputs.category : 'Alege o categorie'}</p>
                                            </div>
                                            <FaAngleDown />
                                        </div>
                                        {categoriesDropwonOpen === true ?
                                            (<div className='add-product-categories-dropdown-menu'>
                                            {categories.map((category, index) => (
                                                <div key={'category' + index} className='add-product-categories-dropdown-menu-item' onClick={() => handleCategoryChange(index)}><p>{category.name}</p></div>
                                            ))}
                                            </div>) : null}
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Pret de baza<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row' style={{marginTop: '-25px'}}>
                                <div className='add-product-prices-list'>
                                    <div className='add-product-prices-list-body' ref={basePricesListParent}>
                                        {prices_list.map((price, index) => (
                                            price.price_type === 'base' && (
                                                <div key={'basePrice' + index}  className='add-product-prices-list-item'>
                                                    <div className='add-product-prices-list-item-top'>
                                                        <div className='add-product-prices-list-item-title'>
                                                            <p>Denumire: </p>
                                                            <input type='text' className='add-product-prices-list-item-title-input' onChange={(e) => updatePrice(index, 'name', e.target.value)} value={price.name}/>
                                                        </div>
                                                        <div className='add-product-prices-list-item-delete-btn' onClick={() => deletePrice(index)}>
                                                            <p>Sterge pretul de baza</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-header'>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Optiune</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Preț</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section add-product-prices-list-last-column'>
                                                            <p>Actiuni</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-body'>
                                                        {price.options.map((option, option_index) => (
                                                            <div key={'basePrice' + index + option_index} className='add-product-prices-option-list-item'>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='text' className='add-product-prices-list-item-input' onChange={(e) => updatePriceOption(index, option_index, 'name', e.target.value)} value={option.name}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='number' className='add-product-prices-list-item-input add-product-prices-list-item-input-price noscroll' onChange={(e) => updatePriceOption(index, option_index, 'value', e.target.value)} value={option.value}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section add-product-prices-list-last-column'>
                                                                    <FaRegTrashAlt className='add-product-prices-list-item-delete-icon' onClick={() => deletePriceOption(index, option_index)}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='add-product-prices-list-add-option-btn' onClick={() => addPriceOption(index)}>
                                                            <FaPlus className='add-product-prices-list-add-option-btn-icon'/>
                                                            <p>Adauga optiune</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left add-product-variation-section'>
                                    <div className='add-product-new-variation-button' onClick={() => addPrice('base')}>
                                        <FaPlus className='add-product-new-variation-button-icon'/>
                                        <p>Adauga optiune pret de baza</p>
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Optiuni fixe pe baza de procentaj [mm]<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row' style={{marginTop: '-25px'}}>
                                <div className='add-product-prices-list'>
                                    <div className='add-product-prices-list-body' ref={fixedPercentagePricesListParent}>
                                        {prices_list.map((price, index) => (
                                            price.price_type === 'fixedPercentage' && (
                                                <div key={'fixedPercentage' + index}  className='add-product-prices-list-item'>
                                                    <div className='add-product-prices-list-item-top'>
                                                        <div className='add-product-prices-list-item-title'>
                                                            <p>Denumire: </p>
                                                            <input type='text' className='add-product-prices-list-item-title-input' onChange={(e) => updatePrice(index, 'name', e.target.value)} value={price.name}/>
                                                        </div>
                                                        <div className='add-product-prices-list-item-delete-btn' onClick={() => deletePrice(index)}>
                                                            <p>Sterge optiunea de procentaj</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-header'>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Optiune</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Valoarea in mm</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section add-product-prices-list-last-column'>
                                                            <p>Actiuni</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-body'>
                                                        {price.options.map((option, option_index) => (
                                                            <div key={'basePrice' + index + option_index} className='add-product-prices-option-list-item'>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='text' className='add-product-prices-list-item-input' onChange={(e) => updatePriceOption(index, option_index, 'name', e.target.value)} value={option.name}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='number' className='add-product-prices-list-item-input add-product-prices-list-item-input-price noscroll' onChange={(e) => updatePriceOption(index, option_index, 'value', e.target.value)} value={option.value}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section add-product-prices-list-last-column'>
                                                                    <FaRegTrashAlt className='add-product-prices-list-item-delete-icon' onClick={() => deletePriceOption(index, option_index)}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='add-product-prices-list-add-option-btn' onClick={() => addPriceOption(index)}>
                                                            <FaPlus className='add-product-prices-list-add-option-btn-icon'/>
                                                            <p>Adauga optiune</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left add-product-variation-section'>
                                    <div className='add-product-new-variation-button' onClick={() => addPrice('fixedPercentage')}>
                                        <FaPlus className='add-product-new-variation-button-icon'/>
                                        <p>Adauga optiune fixa pe baza de procentaj</p>
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Optiuni flexibile pe baza de procentaj [mm]<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row' style={{marginTop: '-25px'}}>
                                <div className='add-product-prices-list'>
                                    <div className='add-product-prices-list-body' ref={rangePercentagePricesListParent}>
                                        {prices_list.map((price, index) => (
                                            price.price_type === 'rangePercentage' && (
                                                <div key={'basePrice' + index}  className='add-product-prices-list-item'>
                                                    <div className='add-product-prices-list-item-top'>
                                                        <div className='add-product-prices-list-item-title'>
                                                            <p>Denumire: </p>
                                                            <input type='text' className='add-product-prices-list-item-title-input' onChange={(e) => updatePrice(index, 'name', e.target.value)} value={price.name}/>
                                                        </div>
                                                        <div className='add-product-prices-list-item-delete-btn' onClick={() => deletePrice(index)}>
                                                            <p>Sterge optiunea de procentaj</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-header-range-percentage'>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Min. mm</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Max. mm</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-body'>
                                                        <div key={'rangePercentage'} className='add-product-prices-option-list-item-range-percentage'>
                                                            <div className='add-product-prices-list-item-section'>
                                                                <input type='number' className='add-product-prices-list-item-input add-product-prices-list-item-input-price noscroll' onChange={(e) => updatePriceOption(index, 0, 'min', e.target.value)} value={price.options[0].min}/>
                                                            </div>
                                                            <div className='add-product-prices-list-item-section'>
                                                                <input type='number' className='add-product-prices-list-item-input add-product-prices-list-item-input-price noscroll' onChange={(e) => updatePriceOption(index, 0, 'max', e.target.value)} value={price.options[0].max}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left add-product-variation-section'>
                                    <div className='add-product-new-variation-button' onClick={() => addPrice('rangePercentage')}>
                                        <FaPlus className='add-product-new-variation-button-icon'/>
                                        <p>Adauga optiune flexibila pe baza de procentaj</p>
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left'>
                                    <p className='add-product-container-left-content-row-left-title'>Optiuni aditionale<span style={{color: 'red'}}>*</span></p>
                                </div>
                                <div className='add-product-container-left-content-row-right'>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row' style={{marginTop: '-25px'}}>
                                <div className='add-product-prices-list'>
                                    <div className='add-product-prices-list-body' ref={additionalPricesListParent}>
                                        {prices_list.map((price, index) => (
                                            price.price_type === 'additional' && (
                                                <div key={'additionalPrice' + index}  className='add-product-prices-list-item'>
                                                    <div className='add-product-prices-list-item-top'>
                                                        <div className='add-product-prices-list-item-title'>
                                                            <p>Denumire: </p>
                                                            <input type='text' className='add-product-prices-list-item-title-input' onChange={(e) => updatePrice(index, 'name', e.target.value)} value={price.name}/>
                                                        </div>
                                                        <div className='add-product-prices-list-item-delete-btn' onClick={() => deletePrice(index)}>
                                                            <p>Sterge optiunea aditionala</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-header'>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Optiune</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section'>
                                                            <p>Preț</p>
                                                        </div>
                                                        <div className='add-product-prices-list-header-section add-product-prices-list-last-column'>
                                                            <p>Actiuni</p>
                                                        </div>
                                                    </div>
                                                    <div className='add-product-prices-list-body'>
                                                        {price.options.map((option, option_index) => (
                                                            <div key={'basePrice' + index + option_index} className='add-product-prices-option-list-item'>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='text' className='add-product-prices-list-item-input' onChange={(e) => updatePriceOption(index, option_index, 'name', e.target.value)} value={option.name}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section'>
                                                                    <input type='number' className='add-product-prices-list-item-input add-product-prices-list-item-input-price noscroll' onChange={(e) => updatePriceOption(index, option_index, 'value', e.target.value)} value={option.value}/>
                                                                </div>
                                                                <div className='add-product-prices-list-item-section add-product-prices-list-last-column'>
                                                                    <FaRegTrashAlt className='add-product-prices-list-item-delete-icon' onClick={() => deletePriceOption(index, option_index)}/>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='add-product-prices-list-add-option-btn' onClick={() => addPriceOption(index)}>
                                                            <FaPlus className='add-product-prices-list-add-option-btn-icon'/>
                                                            <p>Adauga optiune</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='add-product-container-left-content-row'>
                                <div className='add-product-container-left-content-row-left add-product-variation-section'>
                                    <div className='add-product-new-variation-button' onClick={() => addPrice('additional')}>
                                        <FaPlus className='add-product-new-variation-button-icon'/>
                                        <p>Adauga optiune aditionala</p>
                                    </div>
                                </div>
                            </div>

                            <div className='add-product-container-left-content-footer'>
                                {product_submit_spinner === true ? (
                                    <div className='add-product-container-left-content-footer-btn'>
                                        <div className='category_add_form_loader_div'>
                                            <div className='category_add_form_loader'>
                                                <InfinitySpin 
                                                    visible={true}
                                                    width="100"
                                                    color="#3986FF"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='add-product-container-left-content-footer-btn' onClick={handleProductSubmit}>
                                        <FiCheckSquare className='add-product-container-left-content-footer-btn-icon'/>
                                        <p>Creeaza produsul</p>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className='add-product-container-right'>
                        <div className='produse-container-list-item'>
                                <div className='produse-container-list-item-image'>
                                    <img src={imagePreview} alt='Wall panelling'/>                            
                                </div>
                                <div className='produse-container-list-item-info'>
                                    <p className='produse-container-list-item-info-code'>{inputs.category !== '' ? inputs.category : '[Categorie Produs]'}</p>
                                    <p className='produse-container-list-item-info-title'>{inputs.name !== '' ? inputs.name : '[Denumire Produs]'}</p>
                                    <p className='produse-container-list-item-info-stock'>In Stock</p>
                                    <p className='produse-container-list-item-info-price'>£{inputs.price !== '' ? inputs.price : '00.00'}</p>
                                </div>
                            </div>
                    </div>
                </div>
                
                </div>

                {product_submit_status === 'success' && (
                    <div className='add-product-success-popup'>
                        <div className='add-product-success-popup-content'>
                            <div className='add-product-success-popup-body'>
                                <FiCheckSquare className='add-product-success-popup-icon'/>
                                <p style={{fontSize: '18px', fontWeight: '500'}}>Produsul a fost adaugat cu succes.</p>
                                <p style={{opacity: 0.5}}>Vei fi redirectionat automat la pagina de produse in 3 secunde.</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default AddProduct