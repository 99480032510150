import React, { useState, useEffect, useContext, useRef } from 'react'
import './cartPage.css'
import Header from '../../header/header';
import { Cookies } from 'react-cookie';
import { CartContext } from '../cart/cartProvider';
import { APIurl } from '../../../ApiService';
import { useNavigate } from 'react-router-dom';
import { IoCloseOutline } from "react-icons/io5";
import { GoDot } from "react-icons/go";
import { TbAlertHexagonFilled } from "react-icons/tb";
import { set } from 'lodash';

function CartPage() {
    let { getCartItems, getCartItemCount, updateQuantityInCart, removeProductFromCart } = useContext(CartContext);
    const [cartItemsList, setCartItemsList] = useState([]);
    const [quantityInputs, setQuantityInputs] = useState([]);
    useEffect(() => {
        fetchCartItems();
    }, []);

    const fetchCartItems = async() => {
        const cartItems = await getCartItems();
        setCartItemsList([...cartItems]);
        console.log('Cart items:');
        console.log(cartItems);
        let newQuantityInputs = [];
        for(let i = 0; i < cartItems.length; i++){
            const newQuantityInput = {
                value: cartItems[i].quantity,
                alert: false,
                showUpdateBtn: false,
            };
            newQuantityInputs.push(newQuantityInput);
        }
        setQuantityInputs([...newQuantityInputs]);
    }

    const handleQuantityChange = (itemId, inputValue) => {
        // Allow empty input
        if (inputValue === '') {
            let newQuantityInputs = quantityInputs;
            newQuantityInputs[itemId].alert = true;
            newQuantityInputs[itemId].showUpdateBtn = false;
            newQuantityInputs[itemId].value = inputValue;
            setQuantityInputs([...newQuantityInputs]);
            return;
        }
        // Validate integer input
        const isInteger = /^\d+$/.test(inputValue);
        if (isInteger) {
            const intValue = parseInt(inputValue, 10);
            if (intValue < 1) {
                let newQuantityInputs = quantityInputs;
                newQuantityInputs[itemId].alert = true;
                newQuantityInputs[itemId].showUpdateBtn = false;
                newQuantityInputs[itemId].value = inputValue;
                setQuantityInputs([...newQuantityInputs]);
                return;
            } else {
                if(cartItemsList[itemId].quantity !== intValue){
                    let newQuantityInputs = quantityInputs;
                    newQuantityInputs[itemId].alert = false;
                    newQuantityInputs[itemId].showUpdateBtn = true;
                    newQuantityInputs[itemId].value = intValue;
                    setQuantityInputs([...newQuantityInputs]);
                }else{
                    let newQuantityInputs = quantityInputs;
                    newQuantityInputs[itemId].alert = false;
                    newQuantityInputs[itemId].showUpdateBtn = false;
                    newQuantityInputs[itemId].value = intValue;
                    setQuantityInputs([...newQuantityInputs]);
                }
            }
        } else {
            // Invalid input
            let newQuantityInputs = quantityInputs;
            newQuantityInputs[itemId].alert = true;
            newQuantityInputs[itemId].showUpdateBtn = false;
            newQuantityInputs[itemId].value = inputValue;
            setQuantityInputs([...newQuantityInputs]);
            return;
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [cartItemsList]);

    const [totalPrice, setTotalPrice] = useState(0);
    const calculatePrice = () => {
        let totalPrice = 0;
        for(let i = 0; i < cartItemsList.length; i++){
            totalPrice += cartItemsList[i].price * parseInt(quantityInputs[i].value, 10);
        }
        setTotalPrice(totalPrice.toFixed(2));
    }

    const updateQuantity = async(itemId, quantity) => {
        const newCartItems = await updateQuantityInCart(itemId, quantity);
        setCartItemsList([...newCartItems]);
        let newQuantityInputs = quantityInputs;
        newQuantityInputs[itemId].showUpdateBtn = false;
        setQuantityInputs([...newQuantityInputs]);
    }

    const removeProduct = async(itemId) => {
        const newCartItems = await removeProductFromCart(itemId);
        console.log('Cart items after remove in cart page:');
        console.log(newCartItems);
        setCartItemsList([...newCartItems]);
    }

    return (
        <div className='cart-page-section'>
            <Header />
            <div className='shop-hero-container'>
                <p>Your shopping cart</p>
            </div>
            <div className='cart-page-container'>
                <div className='cart-page-container-header'>
                    <p>No. of products in your cart: {getCartItemCount()}</p>
                    <p>Total: £{totalPrice}</p>
                </div>
                <div className='cart-page-container-list'>
                    {cartItemsList.length > 0 ? (cartItemsList.map((item, index) => (
                        <div className='cart-page-container-list-item' key={'cart-page-container-list-item' + index}>
                            <IoCloseOutline className='cart-item-delete-icon' onClick={() => removeProduct(index)}/>
                            <div className='cart-item-header'>
                                <div className='cart-item-header-image-container'>
                                    <img src={process.env.PUBLIC_URL + '/res/images/products/product1.png'} alt='Wall panelling' className='cart-item-header-image'/>
                                </div>
                                <div className='cart-item-header-right'>
                                    <div className='cart-item-header-top'>
                                        <p style={{fontWeight: '500'}}>{item.productName}</p>
                                        <div className='cart-item-header-quantity-div'>
                                            <p className='cart-item-header-quantity-text'>Quantity:</p>
                                            <input type='number' className='cart-item-header-quantity-input' value={quantityInputs[index]?.value} onChange={(e) => handleQuantityChange(index, e.target.value)}/>
                                            {quantityInputs[index]?.alert === true ? (
                                                <div className='cart-item-header-quantity-alert'>
                                                    <TbAlertHexagonFilled className='cart-item-header-quantity-alert-icon'/>
                                                    <p>Minimum quantity is 1</p>
                                                </div>
                                            ) : null}
                                            {quantityInputs[index]?.showUpdateBtn === true ? (
                                                <div className='cart-item-header-quantity-update-btn' onClick={() => updateQuantity(index, parseInt(quantityInputs[index].value, 10))}>
                                                    <p>Update</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='cart-item-configuration'>
                                        {item.baseOptions.length > 0 && item.baseOptions.map((option, index) => (
                                            <div key={index} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                        {item.fixedPercentageOptions.length > 0 && item.fixedPercentageOptions.map((option, index) => (
                                            <div key={index} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                        {item.rangePercentageOptions.length > 0 && item.rangePercentageOptions.map((option, index) => (
                                            <div key={index} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}mm</p>
                                            </div>
                                        ))}
                                        {item.additionalOptions.length > 0 && item.additionalOptions.map((option, index) => (
                                            <div key={index} className='cart-item-configuration-element'>
                                                <GoDot className='cart-item-configuration-element-icon' />
                                                <p>{option.name}: {option.value}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='cart-item-price-div'>
                                        <p className='cart-item-price-per-product'>Price per unit: £{item.price}</p>
                                        <p className='cart-item-price'>Price: £{item.price * item.quantity}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))) : null}
                </div>
            </div>
            <div className='cart-page-to-checkout-btn'>
                <p>Purchase for £{totalPrice}</p>
            </div>
        </div>
    )
}

export default CartPage