import React, { useState, useEffect, useRef, useContext } from 'react'
import './header.css'
import { Link } from 'react-router-dom'
import { FaCartShopping } from "react-icons/fa6";
import autoAnimate from '@formkit/auto-animate';
import { CartContext } from '../shop/cart/cartProvider';

function Header() {
    const { getCartItemCount } = useContext(CartContext);
    const cartItemsCount = getCartItemCount();

    return (
        <div className='headerSection'>
            <div className='headerContainer'>
                <Link to={'/neworder'} className='headerLogo'>
                    <img src='\res\images\myBoardsLogoShadow.png' alt='Logo'/>
                </Link>
                <div className='headerMenu'>
                    <Link to="/shop" className='headerMenuItem' style={{display: 'none'}}>
                        <p>Shop</p>
                    </Link>
                    <Link to="/neworder" className='headerMenuItem'>
                        <p>Cut & Edge</p>
                    </Link>
                    <Link to="/ordershistory" className='headerMenuItem'>
                        <p>Orders</p>
                    </Link>
                    <Link to="/myaccount" className='headerMenuItem'>
                        <p>My Account</p>
                    </Link>
                    <Link to="/cart" className='headerCart' style={{display: 'none'}}>
                        <FaCartShopping/>
                        {cartItemsCount > 0 ? 
                            <div className='headerCartCount'>
                                <p className='headerCartCount-value'>{cartItemsCount}</p>
                            </div> : null
                        }
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header